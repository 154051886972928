import { Box, Stack, Typography } from "@mui/material";
import useApi from "hooks/useApi";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import { processEducationForProfile } from "../utils/processEducation";
import EditProfile from "./editProfile/EditProfile";

const CustomerProfile = () => {
  const id = useSelector((state) => state.auth.user_details?.user?.id);
  const [profile, setProfile] = useState({});
  const [editProfile, setEditProfile] = useState(false);

  const customerStatus = [
    {
      id: "Active",
      label: "Active",
      color: "#0db30d",
    },
    {
      id: "Inactive",
      label: "Inactive",
      color: "#ff564a",
    },
  ];

  const { loading, fetchData } = useApi();

  useEffect(() => {
    const getProfile = async () => {
      const endpoint = {
        method: "get",
        url: `/api/profile/${id}`,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        setProfile(processEducationForProfile(result?.data));
      }
    };
    getProfile();
  }, []);

  return (
    <Box>
      {editProfile ? (
        <EditProfile id={id} setEditProfile={setEditProfile} />
      ) : loading ? (
        <Box
          sx={{
            height: "calc(100vh - 143px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pt: "25px",
              pb: "15px",
            }}
          >
            <Typography variant="h4" mt="10px" px="15px">
              Personal information
            </Typography>
            {/* <Button
              variant="outlined"
              startIcon={<Edit />}
              onClick={() => setEditProfile(true)}
            >
              Edit Profile
            </Button> */}
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
              gap: "15px",
              padding: "20px 0 40px",
              borderTop: "1px solid #ddd",
              px: "15px",
            }}
          >
            <Stack gap="15px">
              <Box>
                <Typography variant="h5">Customer Id</Typography>
                <Typography>{profile?.customer_crm_id || "-"}</Typography>
              </Box>
              <Box>
                <Typography variant="h5">Name</Typography>
                <Typography>{profile?.name || "-"}</Typography>
              </Box>
              <Box>
                <Typography variant="h5">E-mail</Typography>
                <Typography>{profile?.email || "-"}</Typography>
              </Box>
              <Box>
                <Typography variant="h5">Phone</Typography>
                <Typography>{profile?.phone || "-"}</Typography>
              </Box>
            </Stack>
            <Stack gap="15px">
              <Box>
                <Typography variant="h5">Country</Typography>
                <Typography>{profile?.country_name || "-"}</Typography>
              </Box>
              <Box>
                <Typography variant="h5">City</Typography>
                <Typography>{profile?.city || "-"}</Typography>
              </Box>
              <Box>
                <Typography variant="h5">Address</Typography>
                <Typography>{profile?.address || "-"}</Typography>
              </Box>
              <Box>
                <Typography variant="h5">Create at</Typography>
                <Typography>
                  {profile?.created_at
                    ? moment(profile?.created_at).format("ll")
                    : "-"}
                </Typography>
              </Box>
            </Stack>
            <Stack gap="15px">
              <Box>
                <Typography variant="h5">Status</Typography>
                <Box
                  sx={{
                    bgcolor: `${
                      customerStatus?.find(
                        (singleStatus) => singleStatus?.id === profile?.status
                      )?.color
                    }15`,
                    color: customerStatus?.find(
                      (singleStatus) => singleStatus?.id === profile?.status
                    )?.color,
                    textAlign: "center",
                    padding: "3px 5px",
                    borderRadius: "20px",
                    width: "120px",
                    mt: "5px",
                    fontSize: "13px",
                  }}
                >
                  {profile?.status}
                </Box>
              </Box>
            </Stack>
          </Box>
          {profile?.description && (
            <>
              <Typography
                variant="h4"
                py="20px"
                borderBottom="1px solid #ddd"
                px="15px"
              >
                Additional Information
              </Typography>
              <Typography sx={{ py: "20px", px: "15px" }}>
                {profile?.description || "-"}
              </Typography>
            </>
          )}
          {profile?.educations?.length > 0 && (
            <Box mt="20px">
              <Typography variant="h4" px="15px">
                Educational Information
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                  rowGap: "20px",
                  padding: "20px 15px",
                  margin: "20px 0",
                  borderTop: "1px solid #ddd",
                  "& > div:nth-of-type(4), & > div:nth-of-type(5), & > div:nth-of-type(6)":
                    {
                      borderTop: "1px solid #ddd",
                      paddingTop: "20px",
                    },
                }}
              >
                {profile?.educations?.map((edu) => (
                  <Stack
                    gap="20px"
                    sx={{ padding: "0 10px", borderLeft: "1px solid #405189" }}
                  >
                    {edu.hasOwnProperty("name") && (
                      <Box>
                        <Typography variant="h5">Exam Type</Typography>
                        <Typography>{edu?.name || "-"}</Typography>
                      </Box>
                    )}
                    {edu.hasOwnProperty("group") && (
                      <Box>
                        <Typography variant="h5">Group</Typography>
                        <Typography>{edu?.group || "-"}</Typography>
                      </Box>
                    )}
                    {edu.hasOwnProperty("subject_name") && (
                      <Box>
                        <Typography variant="h5">Subject</Typography>
                        <Typography>{edu?.subject_name || "-"}</Typography>
                      </Box>
                    )}
                    {edu.hasOwnProperty("passing_year") && (
                      <Box>
                        <Typography variant="h5">Examination Year</Typography>
                        <Typography>{edu?.passing_year || "-"}</Typography>
                      </Box>
                    )}
                    {edu.hasOwnProperty("gpa") && (
                      <Box>
                        <Typography variant="h5">GPA</Typography>
                        <Typography>{edu?.gpa || "-"}</Typography>
                      </Box>
                    )}
                    {edu.hasOwnProperty("grade") && (
                      <Box>
                        <Typography variant="h5">GRADE</Typography>
                        <Typography>{edu?.grade || "-"}</Typography>
                      </Box>
                    )}
                    {edu.hasOwnProperty("board") && (
                      <Box>
                        <Typography variant="h5">Board</Typography>
                        <Typography>{edu?.board || "-"}</Typography>
                      </Box>
                    )}
                    {edu.hasOwnProperty("institute_name") && (
                      <Box>
                        <Typography variant="h5">Institute</Typography>
                        <Typography>{edu?.institute_name || "-"}</Typography>
                      </Box>
                    )}
                    {edu.hasOwnProperty("university_name") && (
                      <Box>
                        <Typography variant="h5">University</Typography>
                        <Typography>{edu?.university_name || "-"}</Typography>
                      </Box>
                    )}
                  </Stack>
                ))}
              </Box>
            </Box>
          )}
          {profile?.courses?.length > 0 && (
            <Box pt="40px">
              <Typography variant="h4" px="15px">
                English Language Course
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                  padding: "20px 15px",
                  rowGap: "20px",
                  margin: "20px 0",
                  borderTop: "1px solid #ddd",
                }}
              >
                {profile?.courses?.map((course) => (
                  <Box sx={{ padding: "0 10px" }}>
                    <Box
                      sx={{
                        "& table": {
                          borderCollapse: "collapse",
                          width: "100%",
                          marginTop: "10px",
                        },
                        "& td": { border: "1px solid #ddd", padding: "10px" },
                        "& td:first-of-type": {
                          fontWeight: "500",
                        },
                        "& tr:nth-of-type(5) td:last-child": {
                          color: "#d14a22",
                        },
                      }}
                    >
                      <Typography variant="h5" align="center">
                        {course?.course_name}
                      </Typography>
                      <table>
                        <tbody>
                          {course?.course_name === "DUOLINGO" ? (
                            <>
                              <tr>
                                <td>Literacy</td>
                                <td>{course?.literacy}</td>
                              </tr>
                              <tr>
                                <td>Comprehension</td>
                                <td>{course?.comprehension}</td>
                              </tr>
                              <tr>
                                <td>Conversation</td>
                                <td>{course?.conversation}</td>
                              </tr>
                              <tr>
                                <td>Production</td>
                                <td>{course?.production}</td>
                              </tr>
                              <tr>
                                <td>Overall</td>
                                <td>{course?.overall}</td>
                              </tr>
                            </>
                          ) : (
                            <>
                              <tr>
                                <td>Listening</td>
                                <td>{course?.listening}</td>
                              </tr>
                              <tr>
                                <td>Writing</td>
                                <td>{course?.writing}</td>
                              </tr>
                              <tr>
                                <td>Reading</td>
                                <td>{course?.reading}</td>
                              </tr>
                              <tr>
                                <td>Speaking</td>
                                <td>{course?.speaking}</td>
                              </tr>
                              <tr>
                                <td>Overall</td>
                                <td>{course?.overall}</td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {profile?.jobs?.length > 0 && (
            <Box mt="20px">
              <Typography variant="h4" px="15px">
                Job Information
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  rowGap: "20px",
                  padding: "20px 15px",
                  margin: "20px 0",
                  borderTop: "1px solid #ddd",
                  "& > div:nth-of-type(4), & > div:nth-of-type(5), & > div:nth-of-type(6)":
                    {
                      borderTop: "1px solid #ddd",
                      paddingTop: "20px",
                    },
                }}
              >
                {profile?.jobs?.map((job) => (
                  <Stack gap="20px" sx={{ padding: "0 10px" }}>
                    <Box>
                      <Typography variant="h5">Title</Typography>
                      <Typography>{job?.title || "-"}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">Company Name</Typography>
                      <Typography>{job?.company_name || "-"}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">Company Address</Typography>
                      <Typography>{job?.company_address || "-"}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">Join Date</Typography>
                      <Typography>
                        {job?.join_date
                          ? moment(job?.join_date).format("ll")
                          : "-"}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">End Date</Typography>
                      <Typography>
                        {job?.end_date
                          ? moment(job?.end_date).format("ll")
                          : "-"}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">Duration</Typography>
                      <Typography>{job?.duration || "-"}</Typography>
                    </Box>
                  </Stack>
                ))}
              </Box>
            </Box>
          )}
          {profile?.preferred_countries?.length > 0 && (
            <Box pt="40px">
              <Typography variant="h4" px="15px">
                Zone
              </Typography>
              <Box
                sx={{
                  padding: "20px 15px",
                  margin: "20px 0",
                  borderTop: "1px solid #ddd",
                }}
              >
                <Box
                  gap="20px"
                  sx={{
                    display: "flex",
                    flexFlow: "wrap",
                    gap: "10px",
                    "& h6": {
                      backgroundColor: "#efefef",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      fontWeight: "400",
                    },
                  }}
                >
                  {profile?.preferred_countries?.map(({ name }) => (
                    <Typography variant="h6">{name}</Typography>
                  ))}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CustomerProfile;
