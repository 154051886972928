import {
  MdOutlineCategory,
  MdOutlineManageAccounts,
  MdTaskAlt,
} from "react-icons/md";
import { IoMdGlobe } from "react-icons/io";
import { RiWalletLine } from "react-icons/ri";
import { HiOutlineCog } from "react-icons/hi";
import { BiNetworkChart } from "react-icons/bi";
import { AiOutlineProject } from "react-icons/ai";
import { TbBeach, TbMessage, TbSchool, TbTargetArrow } from "react-icons/tb";
import { IoPeopleCircleOutline } from "react-icons/io5";
import { TfiAnnouncement } from "react-icons/tfi";

const setupMenu = [
  {
    label: "Status",
    path: "/setup/status",
    icon: <MdTaskAlt />,
    child: [
      {
        label: "Lead Statuses",
        path: "/setup/status/lead-statuses",
      },
      {
        label: "Project Statuses",
        path: "/setup/status/project-statuses",
      },
      {
        label: "Project Customer Statuses",
        path: "/setup/status/project-customer-statuses",
      },
      {
        label: "Admission Statuses",
        path: "/setup/status/admission-statuses",
      },
      {
        label: "Bank Invoice Statuses",
        path: "/setup/status/bank-invoice-statuses",
      },
      {
        label: "Task Statuses",
        path: "/setup/status/task-statuses",
      },
    ],
  },
  {
    label: "Manage Project",
    path: "/setup/manage-project",
    icon: <AiOutlineProject />,
    child: [
      {
        label: "Branches",
        path: "/setup/manage-project/branches",
      },

      {
        label: "Projects",
        path: "/setup/manage-project/projects",
      },
      {
        label: "Intakes",
        path: "/setup/manage-project/intakes",
      },
      {
        label: "Sponsor Professions",
        path: "/setup/manage-project/sponsor-professions",
      },
      {
        label: "Sponsor Profession Fields",
        path: "/setup/manage-project/sponsor-profession-fields",
      },
      {
        label: "Embassy Fields",
        path: "/setup/manage-project/embassy-fields",
      },
    ],
  },
  {
    label: "Finance",
    path: "/setup/finance",
    icon: <RiWalletLine />,
    child: [
      {
        label: "Fees",
        path: "/setup/finance/fees",
      },
      {
        label: "Currencies",
        path: "/setup/finance/currencies",
      },
      {
        label: "Banks",
        path: "/setup/finance/banks",
      },
      {
        label: "Pay Methods",
        path: "/setup/finance/pay-methods",
      },
      {
        label: "Expense Categories",
        path: "/setup/finance/expense-categories",
      },
      {
        label: "Expense Items",
        path: "/setup/finance/expense-items",
      },
      {
        label: "Bank-Invoice Banks",
        path: "/setup/finance/bank-invoice-banks",
      },
    ],
  },
  {
    label: "Education",
    path: "/setup/education",
    icon: <TbSchool />,
    child: [
      {
        label: "English language Proficiency ",
        path: "/setup/education/elp",
      },
      {
        label: "Faculties",
        path: "/setup/education/faculties",
      },
      {
        label: "Subjects",
        path: "/setup/education/subjects",
      },
      {
        label: "Institutes",
        path: "/setup/education/institutes",
      },
      {
        label: "Universities",
        path: "/setup/education/universities",
      },
      {
        label: "Semesters",
        path: "/setup/education/semesters",
      },
    ],
  },
  {
    label: "Messaging",
    path: "/setup/messaging",
    icon: <TbMessage />,
    child: [
      {
        label: "SMS Templates",
        path: "/setup/messaging/sms-templates",
      },
      {
        label: "Email Templates",
        path: "/setup/messaging/email-templates",
      },
    ],
  },
  {
    label: "University Targets",
    path: "/setup/university-targets",
    icon: <TbTargetArrow />,
  },
  {
    label: "References",
    path: "/setup/references",
    icon: <IoPeopleCircleOutline />,
  },
  {
    label: "Sources",
    path: "/setup/sources",
    icon: <BiNetworkChart />,
  },
  {
    label: "Holidays",
    path: "/setup/holidays",
    icon: <TbBeach />,
  },
  {
    label: "Notice Board",
    path: "/setup/notice",
    icon: <TfiAnnouncement />,
  },
  {
    label: "Zone",
    path: "/setup/zone",
    icon: <IoMdGlobe />,
  },
  {
    label: "Workforce",
    path: "/setup/workforce",
    icon: <MdOutlineManageAccounts />,
    child: [
      {
        label: "Roles",
        path: "/setup/workforce/roles",
      },
      {
        label: "Departments",
        path: "/setup/workforce/departments",
      },
      {
        label: "Designations",
        path: "/setup/workforce/designations",
      },
      {
        label: "Staffs",
        path: "/setup/workforce/staffs",
      },
    ],
  },
  {
    label: "Other",
    path: "/setup/other",
    icon: <MdOutlineCategory />,
    child: [
      {
        label: "Knowledge Base Categories",
        path: "/setup/other/knowledge-base-categories",
      },
    ],
  },
  {
    label: "Settings",
    path: "/setup/settings",
    icon: <HiOutlineCog />,
  },
];

export default setupMenu;
